<template>
    <Modal class="pages-selection" @close="closeForm()" :opened="opened" :close_click_outside="false">
        <template v-slot:header>
            {{ $t('cms.pages_with_content')}}
        </template>
        
        <template v-slot:body>
            <div class="box-layout">
                <div v-if="content && content.pagesNum > 1" class="sticky">
                    <BaseButton class="top-button" @click="disconnectBlock()">
                        {{ $t("cms.disconnect_content_block") }}
                    </BaseButton>
                    <div class="small-note">{{ $t('cms.creates_copy_of_content_block') }}</div>
                </div>
                <LoadedContent  :loaded="isLoaded" :error="isError">
                    <BaseTable :columns="columns" :data="data" />
                    <PageFooter v-if="pageCount > 1">
                        <BasePagination :loadData="loadData" :getUrl="getPageUrl" />
                    </PageFooter>
                </LoadedContent>
            </div>
            
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import BaseTable from "@common/components/crud/BaseTable";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, provide, watchEffect } from 'vue';
import LoadedContent from '@common/components/base/LoadedContent';
// import { useRouter } from 'vue-router';
import BasePagination from '@common/components/crud/BasePagination';
import PageFooter from '@admin/components/base/PageFooter';
import useProject from '@admin/composable/ProjectModel';

export default {
    name: "PagesSelection",
    components: {
        Modal,
        BaseTable,
        LoadedContent,
        BasePagination,
        PageFooter
    },
    emits: ['close','pickItem'],
    props: {
        opened: Boolean,
        content: {
            type: Object,
            default: () => null
        },
        placement_key: {
            type: String,
            default: () => ""
        },
        index: {
            type: Number,
            default: () => 0
        },
        select: {
            type: Boolean,
            default: () => false,
        }
    },
    setup(props,context){
        provide("crud_store","content_page")

        const store = useStore();
 
        const i18n = useI18n();

        // const router = useRouter()

        // loading data
        const loadData = (loadPage = null) => {
            if(!props.opened){
                return;
            }
            let page = loadPage;
            if(!props.content){
                store.dispatch('content_page/fetchItems',{page: page});
            } else {
                store.dispatch('content_page/fetchItems',{page: page,query: {'content': props.content.code}});
            }
            
            
        };
         
        watchEffect(() => {
            loadData();
        })

        const pageCount = computed(() => store.getters['content_page/getPageCount']);
        
        const { 
            hasExtension
        } = useProject();  
        const status = {
            header: "St.",
            header_title: i18n.t("controls.status"),
            type: 'button',
            key: "status",
            icon: (record) => {
                switch(record.status){
                    // active
                    case 20:
                        return 'green_circle'; 
                    // only public
                    case 30:
                        return 'green_empty_circle'; 
                    // private
                    case 10:
                        return 'orange_circle'; 
                    // inactive
                    case 0:
                    default:
                        return 'red_circle'; 
                }
            },
            getTitle: (record) => {
                switch(record.status){
                    // active
                    case 20:
                        return i18n.t('controls.active'); 
                    // only public
                    case 30:
                        return i18n.t('cms.private_access'); 
                    // private
                    case 10:
                        return i18n.t('cms.only_public');
                    // inactive
                    case 0:
                    default:
                        return i18n.t('controls.disabled'); 
                }
            },
            content: () => '',
            action: (record) => {
                let newStatus = 0;
                if(!hasExtension('auth')){
                    if(record.status === 0){
                        newStatus = 20;
                    }
                } else {
                    if(record.status === 0){
                        newStatus = 10;
                    } else if(record.status === 10){
                        newStatus = 20;
                    } else if(record.status === 20){
                        newStatus = 30;
                    }
                }
                store.dispatch('content_page/updateItem',{code: record.code,data: {status: newStatus}});
            },
            filter: false,
            class: "w-xs",
        }

        const title = { 
                type: "info",
                header: i18n.t("cms.title"),
                filter: true,
                key: "title",
                title:i18n.t("cms.title"),
                class: "w-xl",
                // disabled: true,
            };
        let columns = [];
        if(props.select){
            columns = [
                status,
                title,
                {
                    type: "button",
                    title: i18n.t('controls.select_item'),
                    icon: () => "arrow_right",
                    content: () => '',
                    action: (record) => {
                        context.emit('pickItem',record);
                        context.emit('close');
                    }
                }
            ];
        } else {
            columns = [
                {
                    type: "link",
                    icon: () => "go_to_page",
                    content: () => '',
                    link: (record) => 'url.project/:project_code/url.content_pages/!'+record.code,
                },
                title,
                {
                    type: "loader"
                },
                status,
            ]
        }

        // getting data
        const data = computed(() => store.getters['content_page/getItems']);

        const isLoaded = computed(() => store.getters["content_page/isLoaded"]);
        
        const isError = computed(() => store.getters["content_page/isError"]);

        const closeForm = () => {
            context.emit('close');
        }

        // pagination
        const getPageUrl = () => {
            return null;
        }

        const disconnectBlock = () => {
            if(!props.placement_key){
                return;
            }
            store.commit('page_editor/makeContentCopy',{placementCode: props.placement_key, index: props.index});
            closeForm();
        }

        return {
            closeForm,
            columns,
            data,
            isLoaded,
            isError,
            loadData,
            getPageUrl,
            pageCount,
            disconnectBlock
        }

    }
}
</script>

<style scoped>
.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    margin: auto;
}
::v-deep(.base-table){
    margin: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.2rem;
}
.box-layout{
    display: flex;
    align-items: flex-start;
}
.top-button{
    height: 5rem;
}
.sticky{
    position: sticky;
    top: 0;
    width: 10rem;
}
.small-note{
    font-size: 0.75rem;
    padding: 0.5rem;
}
::v-deep(.base-table-row:nth-child(odd) td) {
    background: #fff6e6;
}

::v-deep(.base-table-row:nth-child(even) td) {
    background: #ffe7bb;
}
::v-deep(.base-table-row td) {
    padding: 0.5rem 0.2rem;
}
::v-deep(.base-table-row td.no-padding) {
    padding: 0;
}
::v-deep(.base-table td:first-child) { 
    border-top-left-radius: 1rem; 
    border-bottom-left-radius: 1rem;
    padding: 0.5rem 0.2rem 0.5rem 0.5rem;
}
::v-deep(.base-table td:last-child) { 
    border-top-right-radius: 1rem; 
    border-bottom-right-radius: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
}
::v-deep(.loader .base-loader) {
    left: 2rem;
    top:0;
}

</style>